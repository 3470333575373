/* Shopify-specific styles START */
.grid__item,
.page-width {
  padding: 0 !important;
}

.grid {
  margin-left: 0;
}

@media only screen and (min-width: 750px) {
  .medium-up--push-one-twelfth {
    left: 0;
  }

  .medium-up--five-sixths {
    width: 100%;
  }
}
/* Shopify-specific styles END */

.opt-in-page * {
  font-family: inherit;
}

.opt-in-page {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 6px;
  overflow: hidden;
}

.opt-in-page-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 1rem;
}

.opt-in-page-left-col {
  flex: 2;
  margin-bottom: 1rem;
}

.opt-in-page-left-col-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.opt-in-page-right-col {
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
}

.opt-in-page-logo {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 145px;
  display: block !important;
}

.opt-in-page-main-text {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1rem 0 !important;
  text-transform: none !important;
  letter-spacing: normal !important;
}

.opt-in-page-phone {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 6px 6px 0;
}

.phone-container {
  position: relative;
  display: flex;
  width: 275px;
  min-height: 515px;
  background-color: #fff;
  border-radius: 40px;
  box-shadow: 0px 0px 0px 2px #1f1f1f, 0px 0px 0px 5px #191919, 0px 0px 0px 7px #111;
  flex-direction: column;
  overflow: hidden;
}

.phone-container:before,
.phone-container:after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.phone-container:before {
  top: 0px;
  width: 45%;
  height: 25px;
  background-color: #1f1f1f;
  border-radius: 0px 0px 40px 40px;
}

.phone-container i,
.phone-container b {
  position: absolute;
  display: block;
  color: transparent;
}

.phone-container i {
  top: 0px;
  left: 50%;
  transform: translate(-50%, 6px);
  height: 8px;
  width: 15%;
  background-color: #101010;
  border-radius: 8px;
  box-shadow: inset 0px -3px 3px 0px rgba(256, 256, 256, 0.2);
}

.phone-container b {
  left: -10px;
  top: 0px;
  transform: translate(180px, 4px);
  width: 12px;
  height: 12px;
  background-color: #101010;
  border-radius: 12px;
  box-shadow: inset 0px -3px 2px 0px rgba(256, 256, 256, 0.2);
}

.phone-container b:after {
  content: '';
  position: absolute;
  background-color: #2d4d76;
  width: 6px;
  height: 6px;
  top: 2px;
  left: 2px;
  top: 3px;
  left: 3px;
  display: block;
  border-radius: 4px;
  box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.5);
}

.phone-header {
  flex: 1;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ccc;
}

.phone-header-img {
  max-height: 30px;
  transform: translateY(40%);
  margin: 1.15rem;
}

.phone-body {
  flex: 8;
  display: flex;
  padding: 0.75rem;
}

.phone-body *{
  font-family: Arial, Helvetica, sans-serif !important;
}

.phone-body .chat {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

.phone-body .chat .msg {
  max-width: 85%;
  align-self: flex-start;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-name: showIn;
  animation-play-state: running;
  background-color: #e7e7e7;
  border-radius: 10px;
  color: #000;
  display: inline-block;
  font-size: 12px;
  line-height: 1.3;
  margin: 8px 0 0;
  opacity: 0;
  padding: 8px 10px;
}

.phone-body .chat .msg:nth-child(odd) {
  align-self: flex-end;
  background-color: #3902f0;
  color: #fff;
  font-weight: 600;
}

.phone-body .chat .msg:nth-child(1) {
  animation-delay: 0.5s;
  margin-top: 0;
}

.phone-body .chat .msg:nth-child(2) {
  animation-delay: 1.5s;
}

.phone-body .chat .msg:nth-child(3) {
  animation-delay: 2.4s;
}

.phone-body .chat .msg:nth-child(4) {
  animation-delay: 3.8s;
}

.phone-body .chat .msg:nth-child(5) {
  animation-delay: 4.5s;
}

.phone-body .chat .msg:nth-child(6) {
  animation-delay: 5.2s;
}

@keyframes showIn {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.phone-footer {
  flex: 1;
  display: flex;
}

@media (min-width: 768px) {
  .opt-in-page-row {
    flex-direction: row;
  }

  .opt-in-page-left-col {
    flex-direction: row;
  }

  .opt-in-page-left-col-container {
    max-width: 350px;
  }

  .opt-in-page-right-col {
    flex-direction: row;
  }

  .opt-in-page-promo-copy {
    font-size: 12px;
    transform: translateX(5%);
    margin-bottom: 40px;
    padding: 0 0.5rem;
    width: 215px;
  }

  .phone-container {
    width: 240px;
    min-height: 505px;
  }

  .phone-container b {
    left: -35px;
  }

  .opt-in-page-logo {
    max-height: 145px;
  }

  .opt-in-page-phone {
    justify-content: flex-end;
  }
}

@media (min-width: 992px) {
  .opt-in-page-left-col {
    margin: 0;
  }

  .phone-container {
    width: 275px;
    min-height: 515px;
  }

  .phone-container b {
    left: -10px;
  }

}
